<form [formGroup]="contactForm" (ngSubmit)="send($event)">
  <div class="contact-form">
    <div class="section-field">
      <input class="require" id="contact_name" formControlName="name" type="text" placeholder="Nombre*" />
    </div>
    <div class="section-field">
      <input class="require" id="contact_email" formControlName="email" type="email" placeholder="Correo*" />
    </div>
    <div class="section-field">
      <input class="require" id="contact_phone" formControlName="phone" type="text" placeholder="Teléfono*" />
    </div>
    <div class="section-field">
      <input class="require" id="contact_city" formControlName="city" type="text" placeholder="Ciudad*" />
    </div>
    <div class="section-field textarea">
      <label for="contact_message"></label>
      <textarea id="contact_message" formControlName="message" class="input-message require" placeholder="Comentario*" rows="2"></textarea>
    </div>
    <!-- <div class="ps-checkbox checkbox-alone">
      <fieldset class="checkbox-container">
        <label class="fontNormal">
          <p class="prf">He leído y acepto la <u><a class="link" href="#" onclick="window.open(this.href, '', 'resizable=no,status=no,location=no,toolbar=no,menubar=no,fullscreen=no,scrollbars=no,dependent=no'); return false;" rel="noopener" style="color: #000; text-decoration: underline;">política de privacidad</a>.</u></p>
          <span class="checkmark">
            <input id="politica-privacidad" formControlName="politicaPrivacidad" type="checkbox" />
          </span>
        </label>
        <div class="errorMessage" id="politica-privacidad_em_" style="display:none"></div>
      </fieldset>
    </div> -->
    <div class="section-field iq-mt-20">
      <!-- Recaptcha and other elements -->
    </div>
    <button *ngIf="!loader" id="submit" name="submit" type="submit" [class]="buttonDis ? 'button iq-mt-15 disabled mt-1' : 'button iq-mt-15 mt-1'">Enviar</button>
    <button *ngIf="loader" id="submit" name="submit" type="submit" [class]="buttonDis ? 'button iq-mt-15 disabled mt-1' : 'button iq-mt-15 mt-1'">Enviando...</button>
    <!-- <div class="alert alert-success alert-dismissible fade show" role="alert" id="success">
      <strong>Gracias, Su mensaje ha sido recibido.</strong>.
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div> -->
  </div>
</form>
