import {Component, Input, OnInit,HostListener} from '@angular/core';
import $ from 'jquery';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header-style1',
  templateUrl: './header-style1.component.html',
  styleUrls: ['./header-style1.component.css']
})
export class HeaderStyle1Component implements OnInit { 


  @HostListener('document:scroll', ['$event'])
  onScroll(e) {
      if($(window).scrollTop() === 0){
          this.changeLogo = false;
      }else{
          this.changeLogo = true;
      }
  }

  changeLogo: boolean = false;

  @Input() logoImg: string;

  @Input() navItemList: any[];

  @Input() className: string;

  @Input() styledLogo = true;

  constructor(private router: Router) {}

  ngOnInit() {
  }

  jumpTo(option: any) {
    if (option.route && option.route !== '') {
      this.router.navigate([option.route]); 
    } else {
      $('html, body').stop().animate({
        scrollTop: $(option.href).offset().top
      }, 1500);
    }
  }

}
