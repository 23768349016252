import {Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'app-section-style2',
  templateUrl: './section-style2.component.html',
  styleUrls: ['./section-style2.component.css']
})
export class SectionStyle2Component implements OnInit, AfterViewInit {

  @Input() id: any;

  @Input() small: boolean;
  @Input() slides: any[] = [];
  @Input() products: any[] = [];
  @ViewChild('carousel') carousel: ElementRef;

  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    if (this.carousel) {
      this.carousel.nativeElement.carousel();
    }  
  }
}
