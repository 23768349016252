import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-parallax-hero-style1',
  templateUrl: './parallax-hero-style1.component.html',
  styleUrls: ['./parallax-hero-style1.component.css']
})
export class ParallaxHeroStyle1Component implements OnInit, AfterViewInit {

  @Input() ids: string;
  @Input() classNames: string;
  @Input() bgImage: string;
  @Input() bgVideo: string;
  @Input() imgDefault: string | undefined;
  @Input() colorDefault: string | undefined;

  safeBgImage: SafeUrl | undefined;
  safeBgVideo: SafeUrl | undefined;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.safeBgImage = this.bgImage ? this.sanitizer.bypassSecurityTrustStyle(`url(${this.bgImage})`) : undefined;
    this.safeBgVideo = this.bgVideo ? this.sanitizer.bypassSecurityTrustUrl(this.bgVideo) : undefined;
  }

  ngAfterViewInit() {
    // Aquí puedes manejar cualquier lógica que necesites después de que la vista ha sido inicializada
  }
}