import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import swal from 'sweetalert2';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
const states = [
  {
    "nombre": "Amazonas"
  },
  {
    "nombre": "Anzoátegui"
  },
  {
    "nombre": "Apure"
  },
  {
    "nombre": "Aragua"
  },
  {
    "nombre": "Barinas"
  },
  {
    "nombre": "Bolívar"
  },
  {
    "nombre": "Carabobo"
  },
  {
    "nombre": "Cojedes"
  },
  {
    "nombre": "Delta Amacuro"
  },
  {
    "nombre": "Falcón"
  },
  {
    "nombre": "Guárico"
  },
  {
    "nombre": "Lara"
  },
  {
    "nombre": "Mérida"
  },
  {
    "nombre": "Miranda"
  },
  {
    "nombre": "Monagas"
  },
  {
    "nombre": "Nueva Esparta"
  },
  {
    "nombre": "Portuguesa"
  },
  {
    "nombre": "Sucre"
  },
  {
    "nombre": "Táchira"
  },
  {
    "nombre": "Trujillo"
  },
  {
    "nombre": "Vargas"
  },
  {
    "nombre": "Yaracuy"
  },
  {
    "nombre": "Zulia"
  },
  {
    "nombre": "Distrito Capital"
  },
  {
    "nombre": "Dependencias Federales"
  }
];
 
@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css']
})
export class FormularioComponent implements OnInit {

  public navLogo: string = "../../../../assets/logos/Niu_Protector_negro.png";
  public navItems: any = [];
  statesToShow:any = [];
  public height:'44rem'
  disabled = true;
  public contactForm: FormGroup | any;
  loader:boolean = false;
  public regexNumb: RegExp = /^[0-9]*$/;
  city: any;

  fuente: string;

  constructor(private http: HttpClient,private fb: FormBuilder, private route: ActivatedRoute) { 
    this.contactForm = this.fb.group({
      firstname: ['', Validators.required],
      cedula: ['', [Validators.required, Validators.minLength(7)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(11), Validators.pattern(this.regexNumb)]],
      company:['',Validators.required],
      ciudad: ['', Validators.required],
      // politicaPrivacidad: [false, Validators.requiredTrue]
    });
  }

  ngOnInit(): void {
    this.statesToShow = states;

    this.route.paramMap.subscribe(params => {
      this.fuente = params.get('fuente');
      console.log(this.fuente); // Imprimirá "instagram", "fitelven" o "pagina-web"
    });

  }

  async send(event: Event) {
    this.disabled = true;
    this.loader = true;
    event.preventDefault();
  
    if (this.contactForm.invalid) {
      swal.fire({
        title: 'Error',
        text: 'Por favor, complete todos los campos requeridos',
        icon: 'error',
        timer: 3000
      });
      this.disabled = false; // Rehabilitar el botón en caso de error
      this.loader = false;
      throw new Error('Formulario inválido'); // Lanza una excepción
    }
    
    const value = this.contactForm.value;
    const formData = [
      { property: 'email', value: value.email },
      { property: 'work_email', value: value.email },
      { property: 'firstname', value: value.firstname },
      { property: 'lastname', value: value.firstname },
      { property: 'website', value: 'https://distelnet.com/' },
      { property: 'company', value: value.company || '' },
      { property: 'phone', value: value.phone },
      { property: 'mobilephone', value: value.phone },
      { property: 'origen_lead', value: this.fuente || '' },
      { property: 'hs_analytics_source', value: 'DIRECT_TRAFFIC' },
      { property: 'country', value: 'Venezuela' },
      { property: 'address', value: '' },
      { property: 'hs_timezone', value: 'america_slash_caracas' },
      { property: 'clientId', value: 'distelnet' },
      { property: 'city', value: value.ciudad },
      { property: 'state', value: value.city || 'MA' },
      { property: 'message', value: ` .- Ciudad: ${value.city}  .- Teléfono: ${value.phone} .-Ciudad ${value.ciudad} .-Cedula ${value.cedula}` },
    ];
  
    const query = formData.reduce((str, item) => str + `${item.property}=${encodeURIComponent(item.value)}&`, "").slice(0, -1);
  
    try {
      const request: any = await this.http.get(`${environment.hubspotApi}?${query}`).toPromise();
  
      if (request.error) {
        console.log('Error en la respuesta:', request.error);
        this.disabled = false; // Rehabilitar el botón en caso de error
        throw new Error(request.error); // Lanza una excepción
      } else {
        swal.fire({
          title: 'Muchas gracias por su tiempo, <br> Un asesor se comunicara en breve con usted',
          timer: 3000
        });
        this.contactForm.reset();
        this.disabled = false;
        this.loader = false;
        return {
          error: false,
          data: request,
        };
      }
    } catch (error) {
      console.error('Error durante la solicitud:', error);
  
      if (error.error) {
        swal.fire({
          title: 'Correo electrónico ya registrado',
          text: 'Por favor, intente llenar nuevamente el formulario utilizando otro correo electrónico',
          timer: 3000,
        });
        this.contactForm.reset();
        this.disabled = false;
        this.loader = false;
        throw new Error(error.error.errors[0].message); // Lanza una excepción
      }
  
      this.disabled = false; // Rehabilitar el botón en caso de error
      this.loader = false;
      throw error; // Lanza la excepción capturada
    }
  }

}
