import { Component } from '@angular/core';
import { PluginsService } from './sofbox/plugins.service';
import { Router, NavigationEnd } from "@angular/router";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(
    private plugins: PluginsService,
    private router: Router,
    private titleService: Title,
    private metaService: Meta
  ) { }

  title = 'sofbox-angular';

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.setMetaTags();
  }

  

  setMetaTags() {
    this.titleService.setTitle('Distelnet - Seguridad y Servicios');
    this.metaService.addTags([
      { name: 'description', content: 'NiuProtector ofrece servicio de seguridad, incluyendo camáras y productos de red. Contáctenos para más información.' }, 
      { name: 'keywords', content: 'Seguridad, camáras, productos de red, NiuProtector' },
      { name: 'author', content: 'Distelnet' },
      { name: 'robots', content: 'index, follow' },
      { property: 'og:title', content: 'Distelnet - Seguridad y protección' },
      { property: 'og:description', content: 'Distelnet ofrece servicios de productos de red, conexión, fibra óptica y más' },
      { property: 'og:url', content: 'https://distelnet.com/' },
      { property: 'og:image', content: 'https://niuprotector.net/Niu_Protector_negro.e9a228e5c5a5f758d1a9.png' },
    ]);
  }
}