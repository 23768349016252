import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SofboxModule } from './sofbox/sofbox.module';
import { RecaptchaModule } from 'ng-recaptcha';
import { HttpClientModule } from '@angular/common/http';
import {ContactFormService} from './sofbox/components/contact-form/contact-form.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactFormComponent } from './sofbox/components/contact-form/contact-form.component';
import { FormularioComponent } from './landing-page4/components/formulario/formulario.component';



@NgModule({
  declarations: [
    AppComponent,
    FormularioComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    SofboxModule,
    RecaptchaModule,
    FormsModule,
    HttpClientModule

  ],
  providers: [
    ContactFormService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
