import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RootComponent } from './sofbox/root/root.component';
// import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MobileComponent } from './landing-page4/components/mobile/mobile.component';
import { personasComponent } from './landing-page4/components/personas/personas.component';
import { FormularioComponent } from './landing-page4/components/formulario/formulario.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';


const routes: Routes = [
  /*{ path: '', loadChildren: './landing-page4/landing-page4.module#LandingPage4Module' },*/
  // { path: '', loadChildren: () => import('./landing-page4/landing-page4.module').then(m => m.LandingPage4Module) },
  // { path: 'mobile', component: MobileComponent},
  // { path: 'personas', component: personasComponent},
  // { path: '', loadChildren: () => import('./landing-page4/components/formulario/formulario.component').then(m => m.FormularioComponent) },
  { path: ':fuente', component:FormularioComponent },
  { path: '**', component: FormularioComponent },
  // { path: '/:fuente', component: FormularioComponent},
];
@NgModule({
  imports: [BrowserModule, FormsModule, RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { } 

  