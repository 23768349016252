import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-card-style2',
  templateUrl: './pricing-card-style2.component.html',
  styleUrls: ['./pricing-card-style2.component.css']
})
export class PricingCardStyle2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
