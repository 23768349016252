import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  contactForm: FormGroup;
  disabled = true;
  buttonDis = true;
  public regexNumb: RegExp = /^[0-9]*$/;
  loader =false;
  constructor(
    private http: HttpClient,
    private fb: FormBuilder
  ) {
    // Define el formulario reactivo
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(11), Validators.pattern(this.regexNumb)]],
      city: ['', Validators.required],
      message: ['', Validators.required],
      // politicaPrivacidad: [false, Validators.requiredTrue]
    });
  }

  ngOnInit() {}

  resolved(captchaResponse: string) {
    this.buttonDis = false;
  }

  async send(event: Event) {
    this.disabled = true;
    event.preventDefault();
    this.loader = true;
    if (this.contactForm.invalid) {
      swal.fire({
        title: 'Error',
        text: 'Por favor, complete todos los campos requeridos',
        icon: 'error',
        timer: 3000
      });
      this.loader = false;
      return;
    }

    const value = this.contactForm.value;
    const formData = [
      { property: 'email', value: value.email },
      { property: 'work_email', value: value.email },
      { property: 'firstname', value: value.name },
      { property: 'lastname', value: value.name },
      { property: 'website', value: 'https://niuprotector.net/' },
      { property: 'company', value: value.company || '' },
      { property: 'phone', value: value.phone },
      { property: 'mobilephone', value: value.phone },
      { property: 'origen_led', value: 'WEB' },
      { property: 'hs_analytics_source', value: 'DIRECT_TRAFFIC' },
      { property: 'country', value: 'Venezuela' },
      { property: 'address', value: '' },
      { property: 'hs_timezone', value: 'america_slash_caracas' },

      { property: 'clientId', value: 'niuprotector' },
      { property: 'city', value: value.city },
      { property: 'state', value: value.state || 'MA' },
      { property: 'message', value: ` .- Ciudad: ${value.city}  .- Teléfono: ${value.phone} Mensaje ${value.message}` },
    ];

    const query = formData.reduce((str, item) => str + `${item.property}=${encodeURIComponent(item.value)}&`, "").slice(0, -1);

    try {
      const request: any = await this.http.get(`${environment.hubspotApi}?${query}`).toPromise();

      if (request.error) {
        console.log('Error en la respuesta:', request.error);
      } else {
        swal.fire({
          title: 'Formulario enviado <br> Gracias por su tiempo',
          timer: 3000
        });
        this.contactForm.reset();
        this.disabled = false;
        this.loader = false;
        return {
          error: false,
          data: request,
        };
      }
    } catch (error) {
      console.error('Error durante la solicitud:', error);

      if (error.error) {
        swal.fire({
          title: 'Correo electrónico ya registrado',
          text: 'Por favor, intente llenar nuevamente el formulario utilizando otro correo electrónico',
          timer: 3000,
        });
        this.contactForm.reset();
        this.disabled = false;
        this.loader = false;
        return {
          error: true,
          data: null,
          message: error.error.errors[0].message,
        };
      }

      return {
        error: true,
        data: null,
        message: error,
      };
    }
  }
}
