<div class="main-container">
    <!--<app-header-style1 [logoImg]="navLogo" [navItemList]="navItems" [styledLogo]="false">
    </app-header-style1>-->
    
    <!-- <div style="flex: 1; overflow-y: auto;"> -->
      
      
      <div class="standardBackground" style="min-height: 100%; display: flex; align-items: center; padding: 10px 5px; justify-content: end;">  
        
        <div class="color-font">

            <div style="z-index: 1; width: 20%; margin-bottom: 3%;">
                <img src="../../../../assets/images/form/Logo.png" alt="" style="z-index: 1; width: 100%; height: 100%;" >
            </div>
          
            <div class="iframe" [style.height]="height">
              <div class="Subtitle-container">
                <h2>¡Cotiza aquí!</h2>
                <p style="text-align: center; margin: 0;"> <b> Materiales para la instalación de infraestructuras de redes de Fibra Óptica en Venezuela </b> </p>

              </div>
              <form class="form" (ngSubmit)="send($event)" [formGroup]="contactForm">
                <div class="mb-3">
                  <input
                    name="firstname"
                    formControlName="firstname"
                    type="text"
                    class="form-control"
                    id="firstname"
                    aria-describedby="firstname"
                    placeholder="Nombre y Apellido:"
                    /> 
                </div>
                <div class="mb-3">
                  <input
                    name="cedula"
                    formControlName="cedula"
                    type="text"
                    class="form-control"
                    id="cedula"
                    aria-describedby="cedula"
                    placeholder="RIF:"
                  />
                </div>
                <div class="mb-3">
                  <input
                    name="email"
                    formControlName="email"
                    type="email"
                    class="form-control"
                    id="email"
                    aria-describedby="emailHelp"
                    placeholder="Correo Electrónico:"
                  />
                  <div id="emailHelp" class="form-text"></div>
                </div>
                <div class="mb-3">
                  <input
                    name="phone"
                    formControlName="phone"
                    type="number"
                    class="form-control"
                    id="phone"
                    aria-describedby="phone"
                    placeholder="Número de Teléfono:"
                  />
                </div>
                <div class="mb-3">
                  <input
                    name="company"
                    formControlName="company"
                    type="text"
                    class="form-control"
                    id="company"
                    aria-describedby="company"
                    placeholder="Empresa:"
                  />
                </div>
                <div class="mb-3">
                  <select 
                    required
                    name="Selecciona tu estado"
                    formControlName="state"
                    type="select"
                    class="form-control"
                    id="state"
                    aria-describedby="state"
                    placeholder="-Seleccione su estado-"
                  >                    
                  <option value="--Selecciona tu estado--" disabled selected>--Selecciona tu estado--</option>
                  <option [value]="state.nombre" *ngFor="let state of statesToShow">
                    {{ state.nombre }}
                  </option>
                </select>
                </div>

                <div class="mb-3">
                  <input
                    name="ciudad"
                    formControlName="ciudad"
                    type="text"
                    class="form-control"
                    id="ciudad"
                    aria-describedby="ciudad"
                    placeholder="Ciudad:"
                  />
                </div>
                <div class="checkbox mb-3">
                  <!-- The following line controls and configures the Turnstile widget. -->
                  <div
                    #cloudflare
                    class="cf-turnstile"
                    data-sitekey="0x4AAAAAAAXiT8fieH5WJmLc"
                    data-callback="javascriptCallback"
                  ></div>
                  <!-- end. -->
                </div>
                <button  type="submit" class="btn"> <H3> ENVIAR </H3> </button>
                <button *ngIf="loader" type="submit" class="btn">Cargando...</button>
              </form>
              
            </div>

          </div> 

         </div> 
      </div>


<!-- </div> -->