<section [id]="id" class="overview-block-ptb how-works ">
  <div class="container">
    <div class="row ">      
      <div class="iq-software-demo col-lg-6">
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-wrap="true" data-interval="2800">
          <div class="carousel-inner">
            
          <div *ngFor="let item of slides; let i = index" [class.active]="i === 0" class="carousel-item">
            <img class="d-block w-100 img-fluid-product" [src]="item.imageUrl" [attr.width]="item.width ? item.width : null"
                [attr.height]="item.height ? item.height : null"
                alt="Slide image">
            </div>
          </div>          
        </div>
      </div>   
      <div class="col-lg-6 offset-lg-6">
          <div class="heading-title left text-left">
             <h3 class="iq-tw-7 iq-mb-25 title">Productos</h3>
          </div>
          <ul class="iq-mt-40 iq-list">
             <li class="iq-tw-6 iq-mb-15"  *ngFor="let product of products">
              <i class="ion-android-done-all iq-mr-10 iq-font-blue iq-font-30"></i>
              <span class="iq-font-black">{{product}}</span>
            </li>           
          </ul>
      </div>    
      <div [ngClass]="small ? 'iq-objects-software' : 'iq-objects-softwarenew'">
        <span class="iq-objects-01" data-bottom="transform:translatey(50px)" data-top="transform:translatey(-100px);">
          <img src="./assets/images/drive/03.png" alt="drive02">
        </span>
        <span class="iq-objects-02" data-bottom="transform:translatex(50px)" data-top="transform:translatex(-100px);">
          <img src="./assets/images/drive/04.png" alt="drive01">
        </span>
        <span class="iq-objects-03 iq-fadebounce">
          <span class="iq-round"></span>
        </span>
      </div>
    </div>
  </div>
</section>
